import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { BaseElementsModule } from '@ui';

@Component({
  selector: 'fes-page-access-denied',
  templateUrl: 'access-denied.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BaseElementsModule],
})
export class AccessDeniedComponent {
  @HostBinding('class') classList = 'c-container l-page';
}
