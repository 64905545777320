import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { ButtonComponent } from '@ui';

@Component({
  selector: 'fes-page-not-found',
  templateUrl: 'not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.router.navigate(['**'], { skipLocationChange: true });
  }

  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
