<div class="flex h-full w-full p-5 pt-0">
    <div class="relative flex w-full flex-col items-center justify-center rounded-2xl bg-gray-100">
        <div class="absolute top-0 flex w-full justify-center">
            <span class="absolute -top-[60%] text-[15rem] font-black text-gray-50">404</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="118" height="170" fill="none" viewBox="0 0 118 170">
                <path
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M116.337 162.648s-5.562-16.301-31.585-16.301c0 0-28.37.379-32.034 16.301M4.22 7.213 45.536 114.23 71.195 10.981"
                />
                <path
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M70.327 14.447c0-3.992 3.2-7.234 7.142-7.234 3.944 0 7.143 3.242 7.143 7.234V146.35"
                />
                <path
                    fill="#F2F5CC"
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M74.751 8.752c0 1.516 1.216 2.746 2.72 2.746 1.502 0 2.719-1.23 2.719-2.746V1h-5.431v7.752h-.008Zm-73.251 0c0 1.516 1.216 2.746 2.72 2.746 1.502 0 2.718-1.23 2.718-2.746V1H1.5v7.752Z"
                />
                <path
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M89.03 164.351a10.817 10.817 0 0 1-4.502.974 10.842 10.842 0 0 1-10.621-8.649m21.257-.03a10.772 10.772 0 0 1-2.696 5.23"
                />
                <path
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M68.584 157.148c4.71-.549 10.187-.859 16.029-.859 16.509 0 30.097 2.499 31.724 5.71.109.209.163.433.163.65 0 3.512-14.278 6.351-31.895 6.351-17.617 0-31.895-2.847-31.895-6.351 0-1.904 4.199-3.613 10.854-4.774"
                />
                <path
                    fill="#F2F5CC"
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M44.435 123.356c5.048 0 9.141-4.087 9.141-9.129s-4.093-9.129-9.141-9.129c-5.05 0-9.142 4.087-9.142 9.129s4.093 9.129 9.142 9.129Z"
                />
                <path
                    stroke="#98A300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                    d="M44.437 117.525a3.288 3.288 0 1 1 0-6.576 3.288 3.288 0 0 1 3.16 4.201"
                />
            </svg>
        </div>
        <div class="mt-20 text-md font-semibold text-blue-gray-800">
            Ať svítím jak svítím, možná praskla žárovka, ale tato stránka tu není ...
        </div>
        <ui-button
            styleClass="ui-button-secondary mt-7"
            label="Půjdu radši na Dashboard"
            (onClick)="navigateToDashboard()"
        />
    </div>
</div>
